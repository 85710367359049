<template>
  <div>
    <el-card>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          :key="idx"
          v-for="(breadcrumb, idx) in breadcrumbList"
        >
          <el-link
            v-if="breadcrumb.link != undefined"
            type="text"
            @click="routeTo(breadcrumb.link)"
            class="link_self"
          >
            {{ breadcrumbName(breadcrumb.name) }}
          </el-link>
          <span v-else>{{ breadcrumbName(breadcrumb.name) }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </el-card>
  </div>
</template>

<script>
  //https://ithelp.ithome.com.tw/articles/10204424
  export default {
    name: "Breadcrumb",
    data() {
      return {
        breadcrumbList: [],
      };
    },
    mounted() {
      this.updateList();
    },
    watch: {
      $route() {
        this.updateList();
      },
    },
    methods: {
      breadcrumbName(name) {
        // let workTitle = this.$store.state.page.workTitle
        // if(workTitle == null) workTitle = "內容維護"
        // name = name.replace("{workTitle}",workTitle)
        // //分辨典藏管理
        // if(name.includes("{workType}")){
        //     let workType = this.$store.state.page.worksType
        //     if(workType=="admin")
        //         name = name.replace("{workType}",'典藏管理')
        //     else
        //         name = name.replace("{workType}",'經典編輯')
        // }
        // console.log("name : " , name);
        return name;
      },
      routeTo(link) {
        if (link == undefined) return;
        let realLink = link;
        //分辨典藏管理
        // if(link.includes("{workType}")){
        //     let workType = this.$store.state.page.worksType
        //     if(workType=="admin")
        //         realLink = realLink.replace("{workType}","workAdmin")
        //     else
        //         realLink = realLink.replace("{workType}","work")
        // }
        // if(link.includes("{workId}")){
        //     let workId = this.$store.state.page.workId
        //     realLink = realLink.replace("{workId}",workId)
        // }
        this.$router.push(realLink);
      },
      updateList() {
        this.breadcrumbList = this.$route.meta.breadcrumb;
      },
    },
  };
</script>

<style scoped>
  .breadcrumb {
    margin-bottom: line(3);
  }
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    font-size: font(1);
  }
  li {
    cursor: pointer;
    color: #42b983;
  }
  li:last-child {
    cursor: default;
  }
  li:not(:last-child):after {
    content: ">";
    margin: 5px;
  }
  .link_self {
    text-decoration: none;
    background-color: transparent;
    color: #321fdb;
  }
</style>
