/**
 * 產生 UI[CSidebarNavTitle]元件
 * @param {*} roles 權限列表
 * @param {*} name 名稱
 */
function createSidebarNavTitle(roles, name) {
  return ({
    _name: 'CSidebarNavTitle',
    _children: [name],
    roles: roles
  });
}

/**
 * 產生 UI[CSidebarNavItem]元件
 * @param {*} roles 權限列表
 * @param {*} name 名稱
 * @param {*} to router位置
 * @param {*} isIconUse 是否顯示icon
 * @param {*} iconName icon圖片名
 */
function createSidebarNavItem(roles, name, to, isIconUse = false, iconName = undefined) {
  if (isIconUse) {
    return (
      {
        _name: 'CSidebarNavItem',
        name: name,
        to: to,
        icon: iconName,
        roles: roles
      }
    );
  } else {
    return (
      {
        _name: 'CSidebarNavItem',
        name: name,
        to: to,
        roles: roles
      }
    );
  }
}

export default [
  {
    _name: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'self_dashboard',
  },

  createSidebarNavTitle(
    ['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE',
    'ROLE_SVC_RELATION_EDITABLE', 'ROLE_SVC_RELATION_EXPORTABLE', 'ROLE_SVC_RELATION_READABLE']
    ,'用戶'),

  createSidebarNavItem(
    ['ROLE_SVC_CUSTOMER_EDITABLE', 'ROLE_SVC_CUSTOMER_EXPORTABLE', 'ROLE_SVC_CUSTOMER_READABLE']
    ,'用戶管理','/customerManage',true,'self_list_rich'),     

  createSidebarNavItem(
    ['ROLE_SVC_RELATION_EDITABLE', 'ROLE_SVC_RELATION_EXPORTABLE', 'ROLE_SVC_RELATION_READABLE']
     ,'關係管理','/relationManage',true,'self_relation'),  
    
  createSidebarNavTitle(
    ['ROLE_SVC_USER_EDITABLE', 'ROLE_SVC_USER_EXPORTABLE', 'ROLE_SVC_USER_READABLE',
    'ROLE_SVC_USERGROUP_EDITABLE', 'ROLE_SVC_USERGROUP_EXPORTABLE', 'ROLE_SVC_USERGROUP_READABLE']
     , '權限管理'),

  createSidebarNavItem(
    ['ROLE_SVC_USER_EDITABLE', 'ROLE_SVC_USER_EXPORTABLE', 'ROLE_SVC_USER_READABLE']
    , '帳號管理', '/accountManage', true, 'self_accountManage'),
   
  createSidebarNavItem(
    ['ROLE_SVC_USERGROUP_EDITABLE', 'ROLE_SVC_USERGROUP_EXPORTABLE', 'ROLE_SVC_USERGROUP_READABLE']
    , '權限管理', '/groupTeamManage', true, 'self_groupTeamManage'),

   createSidebarNavTitle(
    ['ROLE_SVC_PLAN_EDITABLE', 'ROLE_SVC_PLAN_EXPORTABLE', 'ROLE_SVC_PLAN_READABLE']
    , '樂易捐方案管理'),
   
    createSidebarNavItem(
      ['ROLE_SVC_PLAN_EDITABLE', 'ROLE_SVC_PLAN_EXPORTABLE', 'ROLE_SVC_PLAN_READABLE']
    , '方案管理', '/planManage', true, 'self_plan'),

  // 範例
  // {
  //   _name: 'CSidebarNavTitle',
  //   _children: ['設定'],
  //   roles: ["ROLE_COMPANY_ADMIN_EDITABLE", "ROLE_COMPANY_ADMIN_READABLE", "ROLE_TEST_READABLE", "ROLE_TEST_EDITABLE"]
  // },
  // {
  //   _name: 'CSidebarNavItem',
  //   name: '公司設定',
  //   to: '/setting/companySetting',
  //   icon: 'self_briefcase',
  //   roles: ["ROLE_COMPANY_ADMIN_EDITABLE", "ROLE_COMPANY_ADMIN_READABLE"]
  // },
  // {
  //   _name: 'CSidebarNavItem',
  //   name: '商店設定',
  //   to: '/setting/storeSetting',
  //   icon: 'self_cube',
  //   roles: ["ROLE_TEST_READABLE", "ROLE_TEST_EDITABLE"]
  // },

]