<template>
  <div class="c-app">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <!-- 自訂 Breadcrumb麵包屑  -->
      <Breadcrumb />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
  import TheSidebar from "./TheSidebar";
  import TheHeader from "./TheHeader";
  import TheFooter from "./TheFooter";
  import Breadcrumb from "../views/components/Breadcrumb";

  export default {
    name: "TheContainer",
    components: {
      TheSidebar,
      TheHeader,
      TheFooter,
      Breadcrumb,
    },
  };
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
