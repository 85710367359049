<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full icon-displacement"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 300 35"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logoThumbnail"
        size="custom-size"
        :height="35"
        viewBox="0 0 35 30"
      />
    </CSidebarBrand>
    <span class="version-label" v-if="!minimize">後台版本：{{ version }}</span>
    <!-- <CRenderFunction flat :content-to-render="$options.nav"/> -->
    <CRenderFunction :contentToRender="computedSidebar" flat />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
  import nav from "./_nav";
  import Util from "../utils";

  export default {
    name: "TheSidebar",
    nav,
    data() {
      return {
         version: process.env.PACKAGE_VERSION,
      }
    },
    computed: {
      show() {
        return this.$store.state.sidebarShow;
      },
      minimize() {
        return this.$store.state.sidebarMinimize;
      },
      currentItems() {
        //sidebar items are not shown until role is known
        return nav.filter((item) => {
          // console.log(item._name)
          // console.log(item.systemModules)
          if(item._name === 'CSidebarNavTitle')
            return Util.Auth.hasRole(item.roles);
          if(item._name === 'CSidebarNavItem')
            return Util.Auth.hasRole(item.roles);  

        });
      },
      computedSidebar() {
        return [
          {
            _name: "CSidebarNav",
            _children: this.currentItems,
          },
        ];
      },
    },
  };
</script>
<style>
.icon-displacement {
  padding-left: 30px;
}
</style>
