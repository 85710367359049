<template>
  <CRow class="row justify-content-center">
    <CCol class="ccol-header-center">
      <h3>
        {{ name }}
        <span class="systemEnvironment-hint-word">{{
          systemEnvironment == "local" || systemEnvironment == "dev"
            ? "(測試環境)"
            : ""
        }}</span>
      </h3>
    </CCol>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <!-- <div class="c-avatar">
          <img src="img/avatars/6.jpg" class="c-avatar-img" />
        </div> -->
          <el-avatar>{{ payerFirstName }}</el-avatar>
        </CHeaderNavLink>
      </template>
      <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader> -->

      <CDropdownItem v-on:click="logout()">
        <CIcon name="self_account_logout" /> Logout
      </CDropdownItem>
    </CDropdown>
  </CRow>
</template>

<script>
  import Utils from "../utils";
  export default {
    name: "TheHeaderDropdownAccnt",
    data() {
      return {
        itemsCount: 42,
        payerFirstName: "CH",
        companyName: "Cheerful Give 團隊",
        name: "CH",
        systemEnvironment: `${process.env.NODE_ENV}`,
      };
    },
    methods: {
      logout() {
        console.log(`logout`);
        Utils.Auth.logOut();
        this.$message.success("成功登出!");
      },
    },
  };
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
  .ccol-header-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .systemEnvironment-hint-word {
    font-size: large;
    color: #fa8857;
  }
</style>
